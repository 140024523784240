.navbar {
    background-color: #68088A;
    color: #FFFFFF;
    display: flex;
    padding: 10px 20px;
    width: 100%;
    justify-content: space-between;

    & .nav-right{
        display: flex;
        justify-content: center;
        text-align: center;
        margin: auto 0;

        @media screen and (max-width: 530px){
            display: none;
        }

        & a {
            margin: auto;
        }

        & h3 {
            padding: 0 20px;
            margin: auto 0;
            cursor: pointer;
        }
        & button {
            padding: 5px 10px;
            cursor: pointer;

            & h3 {
                color: #68088A;
            }
        }

        
    }
}