.activeLink {
  background-color: #68088a;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  margin: 30px 30px 30px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 850px) {
    margin-right: 15px;
}
@media screen and (max-width: 480px) {
  margin-right: 10px;
}

  & span {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
}

.activeLinkGender {
  background-color: #68088a;
  width: 70px;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
margin-right: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & span {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
}

.form-title {
  display: flex;

  & .form-title-content {
    display: flex;
    margin: 0 auto;
  }

  & img {
    height: 200px;
    margin: 0 auto;

    @media screen and (max-width: 1000px) {
      height: 150px;
  }
    @media screen and (max-width: 700px) {
      display: none;
  }
  }

  & h2 {
    text-align: center;
    justify-content: center;
    margin: auto 0 20px auto;
    font-size: 3rem;
    margin-left: 30px;
    font-family: "poppins-bold";
    color: #68088a;
    font-style: italic;

    @media screen and (max-width: 1000px) {
      font-size: 2.5rem;
  }
    @media screen and (max-width: 850px) {
      font-size: 2rem;
  }
  }
}

form {
  width: 65%;
  background-color: #ca69cc;
  color: white;
  border-radius: 40px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 50px 100px;
  font-size: 1.5rem;

  @media screen and (max-width: 1000px) {
    padding: 50px 20px;
}
  @media screen and (max-width: 650px) {
    width: 90%;
}

  & h3 {
    @media screen and (max-width: 850px) {
      font-size: 1.3rem;;
  }
  }
  & label {
    @media screen and (max-width: 850px) {
      font-size: 1.1rem;;
  }
  }

  & input[type="radio"] {
    display: none;
  }

  & input[type="text"] {
   background-color: #ddccdd;
  }

  & input::placeholder {
    color: grey;

    @media screen and (max-width: 480px) {
      font-size: 0.9rem;
  }
  }

  & .radio-container {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background-color: #ddccdd;
    display: inline-flex;
    margin: 30px 30px 30px 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 850px) {
      margin-right: 15px;
  }
    @media screen and (max-width: 480px) {
      margin-right: 10px;
  }
  }

  & .radio-container span {
    font-size: 16px;
    // font-weight: bold;
    color: grey;
  }
  & .radio-container-gender {
    width: 70px;
    height: 40px;
    border-radius: 3px;
    background-color: #ddccdd;
    display: inline-flex;
    margin: 10px 30px 30px 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  & .radio-container-gender span {
    font-size: 16px;
    // font-weight: bold;
    color: grey;
  }

  & .commune {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    padding-left: 30px;
    font-size: 1.3rem;
    border: none;
    border-radius: 3px;

    @media screen and (max-width: 480px) {
      font-size: 0.9rem;
      padding-left: 10px;
  }
  }
  & .cp {
    width: 40%;
    height: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
    font-size: 1.3rem;
    border: none;
    border-radius: 3px;

    @media screen and (max-width: 850px) {
      width: 75%;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    padding-left: 10px;
}
  }

  & .nom {
    width: 100%;
    height: 45px;
    padding-left: 30px;
    font-size: 1.3rem;
    border: none;
    border-radius: 3px;

    @media screen and (max-width: 480px) {
      font-size: 0.9rem;
      padding-left: 10px;
  }
  }

  & .phone {
    margin-top: 20px;
    display: flex;

    @media screen and (max-width: 850px) {
      display: block;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    padding-left: 10px;
}
    & .phone-logo-number {
      display: flex;
      margin-top: 20px;
      
          & .phone-logo {
            width: 45px;
            margin: auto 0;
            text-align: center;
            bottom: 0;

            @media screen and (max-width: 480px) {
              width: 30px;
          }
          }
      
          & .phone-number {
            width: 60%;
            height: 45px;
            // margin-bottom: 20px;
            margin-left: 30px;
            padding-left: 30px;
            font-size: 1.3rem;
            border: none;
            border-radius: 3px;

            @media screen and (max-width: 480px) {
              width: 80%;
              font-size: 0.9rem;
              padding-left: 10px;
            }
          }
    }

    & button {
      margin-left: auto;
      padding: auto 30px;
      width: 300px;
      background-color: #68088a;
      color: white;
      border: none;
      border-radius: 20px;
      font-size: 1.5rem;
      font-weight: 700;
      cursor: pointer;

      @media screen and (max-width: 850px) {
        display: flex;
        margin: 20px auto 0 auto;
        padding: 10px 75px;
    }
      @media screen and (max-width: 480px) {
        width: 250px;
        font-size: 1.2rem;
        padding: 10px 25px 10px 25px;
        justify-content: center;
    }
      @media screen and (max-width: 450px) {
        width: 180px;
        font-size: 1rem;
        padding: 10px 25px 10px 25px;
        justify-content: center;
    }
    }
  }
}
