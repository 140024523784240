.body {
    width: 100%;

    
    & p {
        background-color: #CA69CC;
        color: white;
        display: flex;
        text-align: center;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 70px;
        padding-right: 70px;
        font-size: 2.3rem;
        height: 100%;
        font-weight: 700;

        @media screen and (max-width: 1000px){
            font-size: 2rem;
        }
        @media screen and (max-width: 885px){
            font-size: 1.5rem;
        }
        @media screen and (max-width: 700px){
            font-size: 1.1rem;
        }
    }

    & .xenia-cards {
        
        @media screen and (max-width: 480px) {
            display: none;
        }
        & img {
            margin-top: 100px;
            width: 100%;
            height: 100%;

        }
    }

    & .xenia-real-cards {
        display: none;

        & .card {
            background-color: #68088A;;
            width: 60%;
            margin: 20px auto 20px auto;
            border-radius: 25px;
            padding: 50px 30px;
            text-align: center;
            color: white;
        }


        @media screen and (max-width: 480px) {
            display: block;
        }

        
    }
}