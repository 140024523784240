.header-responsive {
    display: none;

    @media screen and (max-width: 480px) {
        display: block;
    }

    & img {
        width: 100%;
    }
}

.header-content {
    background-image: url('../../assets/img/xenia-header(2).png');
    background-position: center;
    background-size: cover;
    padding-top: 56.25%; /* Hauteur proportionnelle à la largeur (9/16) */
    position: relative;
    height: 100vh;

    @media screen and (max-width: 480px) {
        display: none;
    }

    & h1 {
        color: white;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 40%;
        top: 100px;
        left: 100px;
        position: absolute;
        font-family: 'poppins-bold';
        line-height: 70px;

        @media screen and (max-width: 1180px){
            font-size: 2.5rem;
            top: 50px;
        }
        @media screen and (max-width: 1000px){
            font-size: 2rem;
            line-height: 60px;
        }
        @media screen and (max-width: 800px){
            font-size: 1.7rem;
            line-height: 50px;
        }
        @media screen and (max-width: 640px){
            font-size: 1.6rem;
            line-height: 40px;
        }
    }

    & .header-card {
        background: rgba(255, 255, 255, 0.649);
        position: absolute;
        border-radius: 15px;
        top: 400px;
        width: 40%;
        height: 200px;
        margin-left: 100px;

        @media screen and (max-width: 1180px){
            top: 300px;
        }
        @media screen and (max-width: 1000px){
            top: 250px;
            height: 180px;
        }

        & h3 {
            display: flex;
            justify-content: center;
            font-size: 1.5rem;
            padding-top: 10px;
            font-weight: 800;

            @media screen and (max-width: 1050px){
                font-size: 1.2rem;
            }
            @media screen and (max-width: 810px){
                font-size: 1rem;
            }
            @media screen and (max-width: 680px){
                display: none;
            }
        }

        & .header-card-content {
            display: flex;
            justify-content: center;
            margin-top: 35px;

            @media screen and (max-width: 810px){
                display: block;
                margin: auto;
                text-align: center;
            }
            @media screen and (max-width: 680px){
                margin-top: 25px;
            }

            & p {
                margin: auto 0;
                padding: 0 20px;
                font-size: 1.3rem;

                @media screen and (max-width: 1050px){
                    font-size: 1rem;
                }
                @media screen and (max-width: 680px){
                    font-weight: 800;
                }
            }

            & h2 {
                margin: auto 0;
                font-size: 2.5rem;
                color: #68088A;
                font-family: 'poppins-bold';
            }

        }

        & .star-icon {
            width: 120px;
            height: 120px;
            background: url('../../assets/img/star-icon.svg') center no-repeat;
            background-size: 120px;
            text-align: center;
            justify-content: center;
            position: absolute;
            bottom: -40px;
            right: -40px;

            @media screen and (max-width: 1000px){
                bottom: -60px;
            right: -60px;
            }

            & p {
                margin-top: 30px;
                font-weight: 800;
            }
        }

    }
}