.footer {
    width: 100%;
    height: 100px;
    background-color: #68088a;
    display: flex;

    @media screen and (max-width: 550px) {
        display: inline-table;
    }

    & .footer-content {
        justify-content: center;
        text-align: center;
        margin: auto 50px auto auto;
        color: white;

        @media screen and (max-width: 550px) {
            width: 100%;
            font-size: 0.6rem;
        }
    }

    & .footer-logo{
        width: 150px;
        justify-content: center;
        display: flex;

        @media screen and (max-width: 550px) {
            width: 100%;
        }
        

        & img {
            margin-top: 10px;
            margin-left: 100px;

            @media screen and (max-width: 550px) {
               width: 200px;
               margin-left: 0;
            }
        }
    }
}